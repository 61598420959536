<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" xl="3" lg="4" :class="$vuetify.breakpoint.mdAndDown ? 'pa-0' : ''">
        <files-sidebar />
      </v-col>
      <v-col cols="12" xl="6" lg="8" :class="$vuetify.breakpoint.mdAndDown ? 'px-1' : ''">
        <vue-page-transition name="fade-in-down">
          <router-view :key="$route.fullPath" />
        </vue-page-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import FilesSidebar from '@/modules/files/components/FilesSidebar'
  export default {
    name: 'FilesSection',
    components: {FilesSidebar}
  }
</script>
