<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-navigation-drawer v-model="localDrawer" :value="menuDrawerMobile"
                       :width="$vuetify.breakpoint.mdAndDown ? '90%' : '100%'" :class="$vuetify.breakpoint.mdAndDown ? 'navigation-mobile-container' : ''"
                       :height="$vuetify.breakpoint.lgAndUp ? 'auto' : 'calc(100% - 112px)'"
                       :permanent="$vuetify.breakpoint.lgAndUp" :fixed="$vuetify.breakpoint.mdAndDown"
  >
    <v-card flat class="h-100">
      <v-list-item inactive two-line>
        <v-list-item-avatar>
          <font-awesome-icon :icon="['fad', 'folder-open']" size="2x" class="primary--text" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">Mes documents</v-list-item-title>
          <v-list-item-subtitle class="secondary--text text--darken-1 font-weight-light font-alt">Ma liste de documents</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list nav>
        <v-list-item link :to="{name: 'my-files'}" exact>
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'folder-open']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Mes documents</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="openFileCreate">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'folder-plus']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Ajouter un document</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'FilesSidebar',
    computed: {
      ...mapState('layout', ['menuDrawerMobile']),
      localDrawer: {
        get() {
          return this.$store.state.layout.menuDrawerMobile
        },
        set(value) {
          this.$store.commit('layout/setDrawerMobile', value)
        }
      }
    },
    methods: {
      openFileCreate() {
        this.$root.$emit('modal:open', {
          modal: () => import('@/modules/files/components/FileCreate')
        })
      }
    }
  }
</script>
